import React, { Component } from 'react';

class FaqComponent extends Component {
    toggleQue = (id) => {
        document.getElementById(id).classList.toggle('active');
    }
    render() {
        return (
            <div className="faq-page">
             <div className="container">
                <div className="top5-questions">
                    <h2>FAQs</h2>
                    <ul>
                        <li id="que1">
                            <h3 className="que" onClick={() => this.toggleQue('que1')}>Why should I get Samsung Care+?</h3>
                            <div className="ans">
                                <p>You should get Samsung Care+ because it protects your Galaxy device from any damage that is not covered by our Official Warranty. Should anything happen to it, we’ll repair it within 24 hours! We’ll collect, and deliver the device wherever you are.</p>
                            </div>
                        </li>
                        <li id="que2">
                            <h3 className="que" onClick={() => this.toggleQue('que2')}>What are the benefits of Samsung Care+?</h3>
                            <div className="ans">
                                <ul>
                                    <li>Your device is protected from any unintentional damage such as broken screen and liquid spills (even soft drinks and coffee!).</li>
                                    <li>Just give us a call and we’ll pick-up and return your device wherever you are (you don’t even have to take time off from work)</li>
                                    <li>Your device will be repaired by Samsung Authorized Repairers using only Original Parts.</li>
                                    <li>After a repair is completed, you’ll get a 3-months Repair Warranty. If the device is still not working as it should we’ll do it all again at no extra cost.</li>
                                    <li>If the damage to the device is too extensive, we’ll give you a replacement phone so you don’t have to buy a new one.</li>
                                </ul>
                            </div>
                        </li>
                        <li id="que3">
                            <h3 className="que" onClick={() => this.toggleQue('que3')}>What is the difference between Samsung Care+ and Samsung Manufacturer’s Warranty?</h3>
                            <div className="ans">
                                <ul>
                                    <li>Samsung Manufacturer’s Warranty covers breakdowns due to mechanical and electrical failures or manufacturing defects.</li>
                                    <li>Samsung Care+ covers everything else that might happen to your phone caused by external force and water/liquid such as cracked screen and water spills.</li>
                                </ul>
                            </div>
                        </li>
                        <li id="que4">
                            <h3 className="que" onClick={() => this.toggleQue('que4')}>How can I buy Samsung Care+?</h3>
                            <div className="ans">
                                <p>It’s simple</p>
                                <ul>
                                    <li>Just enter your Name, Email and Phone Number</li>
                                    <li>Make a payment (psst..this is a secure site!)</li>
                                    <li>Activate your protection by entering your IMEI</li>
                                </ul>
                            </div>
                        </li>
                        <li id="que5">
                            <h3 className="que" onClick={() => this.toggleQue('que5')}>What is an IMEI Number and why do you need it?</h3>
                            <div className="ans">
                                <p>IMEI Number is a unique identifier for your Galaxy device. Think of it as your National ID or Passport number. We use it to generate a service contract so you are protected if something unfortunate happens. Your IMEI Number will also be used to identify your device when you request a repair so it doesn’t get sent to someone else!</p>
                            </div>
                        </li>

                        <li id="que6">
                            <h3 className="que" onClick={() => this.toggleQue('que6')}>What happens if I don’t activate my Samsung Care+?</h3>
                            <div className="ans">
                                <p>Sadly, we won’t be able to protect your Galaxy device. Not to worry though, we will refund the amount you paid for within 14 days from the date you made the payment. </p>
                            </div>
                        </li>

                        <li id="que7">
                            <h3 className="que" onClick={() => this.toggleQue('que7')}>How do I request for a repair?</h3>
                            <div className="ans">
                                <p>Call our hotline within 14 days after the unintentional breakage happened to your phone. We’ll collect, repair, and return your phone in as fast as 24 hours and within 3 working days in Metro Manila or 5 working days in other areas. </p>
                            </div>
                        </li>
                        <li id="que8">
                            <h3 className="que" onClick={() => this.toggleQue('que8')}>When will my Samsung Care+ expire?</h3>
                            <div className="ans">
                                <p>Your Samsung Care+ repair plan will expire:</p>
                                <ul>
                                    <li>At the end of the service plan term</li>
                                    <li>When the cost of repair has reached your phone’s purchase price</li>
                                    <li>When one replacement phone has been given to you</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
        )
    }
}

export default FaqComponent;