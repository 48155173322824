import React from 'react';
import Layout from '../components/shared/layout'
import FaqComponent from '../components/faq/faq.component'

export default function FaqPage() {
    return (
        <Layout header={true} isBackBtn={true}>
            <FaqComponent></FaqComponent>
        </Layout>
    )
}